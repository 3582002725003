import React from "react";
import { Col, Row, Table, Image } from "react-bootstrap";
import deleteButton from "../../assets/delete.png";
import editButton from "../../assets/edit.png";
import viewButton from "../../assets/view.png";

export default function ProductList({
  products,
  editButtonHandeler,
  inventoryDetailsHandler,
  deleteButtonHandler,
}) {
  return (
    <div>
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{
            margin: "20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1>Product List</h1>
        </Col>
      </Row>
      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>SKU ID</th>
            <th>Product Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {products?.length > 0 ? (
            products.map((product, index) => (
              <tr key={product.id}>
                <td>{index + 1}</td>
                <td>{product.sku}</td>
                <td>{product.product_name}</td>
                <td style={{ display: "flex", justifyContent: "space-around" }}>
                  <Image
                    src={editButton}
                    alt="edit"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      editButtonHandeler(
                        product?.id,
                        product?.product_name,
                        product?.sku
                      )
                    }
                  />
                  <Image
                    src={viewButton}
                    alt="view"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => inventoryDetailsHandler(product?.id)}
                  />
                  <Image
                    src={deleteButton}
                    alt="delete"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteButtonHandler(product?.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
