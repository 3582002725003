import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import deleteButton from "../../assets/delete.png";
import editButton from "../../assets/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchShipmentDateList from "../../redux/thunk/fetchShipmentDateList";
import fetchMonth from "../../redux/thunk/fetchMonth";
import fetchCreateShipment from "../../redux/thunk/fetchCreateShipment";
import Success from "../ui/Success";
import fetchshipmentDateUpdate from "../../redux/thunk/fetchShipmentDateUpdate";
import fetchShipmentDateDelete from "../../redux/thunk/fetchShipmentDateDelete";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function ManageShipDate() {
  const [shouldReload, setShouldReload] = useState(false);
  const [month, setMonth] = useState("");
  const [shipment_id, setShipmentId] = useState("");
  const [shipdate, setShipdate] = useState("");
  const [maxpullet, setMaxpullet] = useState("");
  const [maxorder, setMaxorder] = useState("");
  const [availability, setAvailability] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const monthList = useSelector((state) => state.monthList);
  const shipmentDateList = useSelector((state) => state.shipmentDateList);
  const createShipment = useSelector((state) => state.createShipment);
  const updateShipment = useSelector((state) => state.updateShipment);
  const deleteShipment = useSelector((state) => state.deleteShipment);
  const {
    isError: monthIsError,
    error: monthError,
    loading: monthLoading,
    months,
  } = monthList || {};
  const { loading: deleteLoading } = deleteShipment || {};
  const {
    isError: listIsError,
    loading: listLoading,
    error: listError,
    shipments,
  } = shipmentDateList || {};
  const {
    isError: createIsError,
    loading: createLoading,
    error: createError,
    success,
  } = createShipment || {};
  const {
    isError: updateIsError,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = updateShipment || {};
  const { userInfo } = login || {};
  const reset = useCallback(() => {
    setMonth("");
    setShipdate("");
    setMaxorder("");
    setMaxpullet("");
  }, []);
  const editShipDate = (e) => {
    e.preventDefault();
    let shipment = {
      month: month,
      date: shipdate,
      max_pullet: maxpullet,
      max_order: maxorder,
      availability: availability,
    };
    dispatch(fetchshipmentDateUpdate(shipment_id, shipment));
  };
  const createShipDate = (e) => {
    e.preventDefault();
    let shipment = {
      month: month,
      date: shipdate,
      max_pullet: maxpullet,
      max_order: maxorder,
    };

    dispatch(fetchCreateShipment(shipment));
  };
  const cancelHandler = (e) => {
    e.preventDefault();
    reset();
    setEditMode(false);
  };
  const editButtonHandeler = (
    id,
    month_id,
    shipdate,
    max_pullet,
    max_order,
    availability
  ) => {
    setEditMode(true);
    setShipmentId(id);
    setMonth(month_id);
    setShipdate(shipdate);
    setMaxorder(max_order);
    setMaxpullet(max_pullet);
    setAvailability(availability);
  };
  const deleteButtonHandler = (id) => {
    dispatch(fetchShipmentDateDelete(id)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    if (success || updateSuccess) {
      reset();
    }
    dispatch(fetchShipmentDateList);
    dispatch(fetchMonth);
  }, [
    navigate,
    userInfo,
    dispatch,
    success,
    reset,
    updateSuccess,
    shouldReload,
  ]);
  return (
    <Container>
      {(success || updateSuccess) && (
        <Success
          message={
            success
              ? "Shipment Date Create Successfully"
              : "Shipment Date Update Successfully"
          }
        />
      )}

      {(createLoading ||
        deleteLoading ||
        listLoading ||
        updateLoading ||
        monthLoading) && <Loading />}

      {(createIsError || listIsError || updateIsError || monthIsError) && (
        <Error
          message={
            createIsError
              ? createError
              : listIsError
              ? listError
              : updateIsError
              ? updateError
              : monthError
          }
        />
      )}
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Manage ShipDate</h1>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <Form
            onSubmit={editMode ? editShipDate : createShipDate}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Form.Group controlId="month" style={{ margin: "10px" }}>
              <Form.Label>Select Month</Form.Label>
              <Form.Control
                as="select"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                required
              >
                <option value="">Please Select Month</option>
                {months?.map((month, index) => (
                  <option key={index} value={month?.id}>
                    {`${month?.month_name} ${month?.year}`}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="shipdate" style={{ margin: "10px" }}>
              <Form.Label>Ship Date</Form.Label>
              <Form.Control
                type="date" // Input type set to "date"
                value={shipdate} // Using shipdate as the value
                onChange={(e) => setShipdate(e.target.value)} // Update state with setShipdate
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="maxpullet" style={{ margin: "10px" }}>
              <Form.Label>Max Pullet</Form.Label>
              <Form.Control
                type="number"
                value={maxpullet}
                onChange={(e) => setMaxpullet(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="maxorder" style={{ margin: "10px" }}>
              <Form.Label>Max Order</Form.Label>
              <Form.Control
                type="number"
                value={maxorder}
                onChange={(e) => setMaxorder(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="availability" style={{ margin: "10px" }}>
              <Form.Check // Changed to Form.Check for checkbox
                type="checkbox" // Input type set to "checkbox"
                label="Availability" // Added label prop
                checked={availability} // Use checked for checkbox
                onChange={(e) => setAvailability(e.target.checked)} // Update state with checked value
              />
            </Form.Group>
            <Button
              variant={editMode ? "outline-success" : "outline-info"}
              style={{ width: "180px", marginRight: "20px" }}
              type="submit"
            >
              {editMode ? "Update" : "Create"}
            </Button>
            <Button
              variant="outline-danger"
              onClick={cancelHandler}
              style={{ width: "180px" }}
            >
              Cancel
            </Button>
          </Form>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Month</th>
            <th>Ship Date</th>
            <th>Max Pullet</th>
            <th>Max Order</th>
            <th>Total Order</th>
            <th>Total Pullet</th>
            <th>Availability</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {Array.isArray(shipments) &&
            shipments?.map((shipment, index) => (
              <tr key={shipment?.id}>
                <td>{shipment?.month}</td>
                <td>{shipment?.shipment_date}</td>
                <td>{shipment?.max_pullet}</td>
                <td>{shipment?.max_order}</td>
                <td>{shipment?.total_order}</td>
                <td>{shipment?.total_pullet}</td>
                <td>
                  <p
                    style={{
                      background: shipment?.availability ? "green" : "red",
                      borderTopLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                      color: "white",
                    }}
                  >
                    {shipment?.availability ? "available" : "not available"}
                  </p>
                </td>
                <td style={{ display: "flex", justifyContent: "space-around" }}>
                  <Image
                    src={editButton}
                    alt="edit"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      editButtonHandeler(
                        shipment?.id,
                        shipment?.month_id,
                        shipment?.date,
                        shipment?.max_pullet,
                        shipment?.max_order,
                        shipment?.availability
                      )
                    }
                  />
                  <Image
                    src={deleteButton}
                    alt="delete"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteButtonHandler(shipment?.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}
