import { ADD_PRODUCT_FAIL, ADD_PRODUCT_REQUEST } from "../product/actionType";
import { addProduct } from "../product/actions";
import axios from "./utils/axios";

const fetchAddProduct = (sku, product) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_PRODUCT_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        "api/product/create/",
        { sku: sku, product_name: product },
        config
      );
      dispatch(addProduct(data));
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchAddProduct;
