import { updateShipmentDate } from "../order/actions";
import {
  UPDATE_SHIPMENT_DATE_FAIL,
  UPDATE_SHIPMENT_DATE_REQUEST,
} from "../order/actionType";

import axios from "./utils/axios";

const fetchshipmentDateUpdate = (shipment_id, shipment) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_SHIPMENT_DATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/order/update_shipment/${shipment_id}/`,
        shipment,
        config
      );
      dispatch(updateShipmentDate(data));
    } catch (error) {
      dispatch({
        type: UPDATE_SHIPMENT_DATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchshipmentDateUpdate;
