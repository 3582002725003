import { shipmentDateReport } from "../order/actions";
import {
  SHIPMENT_DATE_REPORT_FAIL,
  SHIPMENT_DATE_REPORT_REQUEST,
} from "../order/actionType";
import axios from "./utils/axios";

const fetchShipmentReport = (startDate, endDate) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHIPMENT_DATE_REPORT_REQUEST });
      const {
        login: { userInfo },
      } = getState();

      if (!userInfo || !userInfo.token) {
        throw new Error("No user token found");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `api/order/shipment_report/${startDate}/${endDate}/`, // Pass an empty object as the data payload
        config
      );

      dispatch(shipmentDateReport(data));
    } catch (error) {
      console.error("Error:", error.response || error.message);

      dispatch({
        type: SHIPMENT_DATE_REPORT_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};

export default fetchShipmentReport;
