import {
  MONTHLY_PULLET_INVENTORY_FAIL,
  MONTHLY_PULLET_INVENTORY_REQUEST,
} from "../pullet/actionType";
import { createMonthlyPullet } from "../pullet/actions";
import axios from "./utils/axios";

const fetchMonthlyPulletCreate = (productId, pullet) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: MONTHLY_PULLET_INVENTORY_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(`api/pullet/monthly_create/${productId}/`, pullet, config);
      dispatch(createMonthlyPullet(data));
    } catch (error) {
      dispatch({
        type: MONTHLY_PULLET_INVENTORY_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchMonthlyPulletCreate;
