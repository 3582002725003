import {
  MONTHLY_PULLET_INVENTORY_FAIL,
  MONTHLY_PULLET_INVENTORY_REQUEST,
  MONTHLY_PULLET_INVENTORY_SUCCESS,
  PULLET_INVENTORY_CREATE_FAIL,
  PULLET_INVENTORY_CREATE_REQUEST,
  PULLET_INVENTORY_CREATE_SUCCESS,
  PULLET_INVENTORY_DELETE_FAIL,
  PULLET_INVENTORY_DELETE_REQUEST,
  PULLET_INVENTORY_DELETE_SUCCESS,
  PULLET_INVENTORY_LIST_FAIL,
  PULLET_INVENTORY_LIST_REQUEST,
  PULLET_INVENTORY_LIST_SUCCESS,
  PULLET_INVENTORY_UPDATE_FAIL,
  PULLET_INVENTORY_UPDATE_REQUEST,
  PULLET_INVENTORY_UPDATE_SUCCESS,
} from "./actionType";

export const pulletInventoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PULLET_INVENTORY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PULLET_INVENTORY_CREATE_SUCCESS:
      return {
        loading: false,
        pulletInventory: action.payload,
        success: true,
        isError: false,
      };
    case PULLET_INVENTORY_CREATE_FAIL:
      return {
        loading: false,
        pulletInventory: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const pulletInventoryMonthlyCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTHLY_PULLET_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MONTHLY_PULLET_INVENTORY_SUCCESS:
      return {
        loading: false,
        pulletInventory: action.payload,
        success: true,
        isError: false,
      };
    case MONTHLY_PULLET_INVENTORY_FAIL:
      return {
        loading: false,
        pulletInventory: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const pulletInventoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PULLET_INVENTORY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PULLET_INVENTORY_UPDATE_SUCCESS:
      return {
        loading: false,
        pulletInventory: action.payload,
        success: true,
        isError: false,
      };
    case PULLET_INVENTORY_UPDATE_FAIL:
      return {
        loading: false,
        pulletInventory: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const pulletInventoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PULLET_INVENTORY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PULLET_INVENTORY_DELETE_SUCCESS:
      return {
        loading: false,
        pulletInventory: action.payload,
        success: true,
        isError: false,
      };
    case PULLET_INVENTORY_DELETE_FAIL:
      return {
        loading: false,
        pulletInventory: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const pulletListReducer = (state = [], action) => {
  switch (action.type) {
    case PULLET_INVENTORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PULLET_INVENTORY_LIST_SUCCESS:
      return {
        loading: false,
        pullet_list: action.payload,
        success: true,
        isError: false,
      };
    case PULLET_INVENTORY_LIST_FAIL:
      return {
        loading: false,
        pullet_list: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
