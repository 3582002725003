import { deleteProduct } from "../product/actions";
import {
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
} from "../product/actionType";
import axios from "./utils/axios";

const fetchDeleteProduct = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete(`api/product/delete/${id}/`, config);
      dispatch(deleteProduct(data));
    } catch (error) {
      dispatch({
        type: DELETE_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchDeleteProduct;
