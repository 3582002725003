import { shipmentAvaibility } from "../order/actions";
import {
  SHIPMENT_AVAIBILITY_CHANGE_FAIL,
  SHIPMENT_AVAIBILITY_CHANGE_REQUEST,
} from "../order/actionType";
import axios from "./utils/axios";

const fetchShipmentAvaibility = (searchDate) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHIPMENT_AVAIBILITY_CHANGE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/order/change_avaibility/${searchDate}/`,
        {},
        config
      );
      dispatch(shipmentAvaibility(data));
    } catch (error) {
      dispatch({
        type: SHIPMENT_AVAIBILITY_CHANGE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchShipmentAvaibility;
