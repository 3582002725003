import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchProductList from "../../redux/thunk/fetchProductList";
import fetchUpdateProduct from "../../redux/thunk/fetchUpdateProduct";
import fetchAddProduct from "../../redux/thunk/fetchAddProduct";
import fetchDeleteProduct from "../../redux/thunk/fetchDeleteProduct";
import fetchPulletList from "../../redux/thunk/fetchPulletList";
import ProductList from "./ProductList";
import Chart from "./Chart";

export default function Product() {
  const [sku, setSku] = useState("");
  const [id, setId] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [product, setProduct] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isToggled, setIsToggled] = useState(false); // New state for the toggle
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const productList = useSelector((state) => state.productList);
  const updateProduct = useSelector((state) => state.updateProduct);
  const addProduct = useSelector((state) => state.addProduct);
  const deleteProduct = useSelector((state) => state.deleteProduct);
  const { isError, loading, products, error } = productList || {};
  const {
    isError: updateIsError,
    loading: updateLoading,
    error: updateError,
  } = updateProduct || {};
  const {
    isError: addIsError,
    loading: addLoading,
    error: addError,
  } = addProduct || {};
  const {
    isError: deleteIsError,
    loading: deleteLoading,
    error: deleteError,
  } = deleteProduct || {};
  const { userInfo } = login || {};

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchProductList);
    dispatch(fetchPulletList);
  }, [navigate, userInfo, dispatch, shouldReload]);

  const createProductHandler = (e) => {
    e.preventDefault();
    dispatch(fetchAddProduct(sku, product)).then(() => {
      setProduct("");
      setSku("");
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };

  const inventoryDetailsHandler = (id) => {
    navigate(`/pullet_inventory/${id}`);
  };

  const updateProductHandler = (e) => {
    e.preventDefault();
    dispatch(fetchUpdateProduct(id, sku, product)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
    setEditMode(false);
    setProduct("");
    setSku("");
  };

  const editButtonHandeler = (id, product_name, sku) => {
    setProduct(product_name);
    setSku(sku);
    setId(id);
    setEditMode(true);
  };

  const deleteButtonHandler = (id) => {
    dispatch(fetchDeleteProduct(id)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };

  const cancelButton = () => {
    setEditMode(false);
    setProduct("");
    setSku("");
  };

  const handleToggleChange = () => {
    setIsToggled((prev) => !prev);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {(loading || updateLoading || addLoading || deleteLoading) && (
          <Loading />
        )}
        {(isError || updateIsError || addIsError || deleteIsError) && (
          <Error
            message={
              isError
                ? error
                : updateIsError
                ? updateError
                : addIsError
                ? addError
                : deleteError
            }
          />
        )}
      </div>
      <Row className="justify-content-md-center">
        <Col>
          <Form
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "20px 0px",
              alignItems: "center",
            }}
            onSubmit={editMode ? updateProductHandler : createProductHandler}
          >
            <Form.Group controlId="sku">
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter SKU Number"
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="product">
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter Product Name"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Button variant={editMode ? "success" : "primary"} type="submit">
              {editMode ? <>Update Product</> : <>Create Product</>}
            </Button>
            {editMode && (
              <Button variant="danger" onClick={cancelButton}>
                Cancel
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          float: "right",
          marginRight: "40px",
        }}
      >
        <div
          style={{ fontSize: "16px", fontWeight: "bold", marginRight: "10px" }}
        >
          ChartBox
        </div>
        <div className="toggle-switch">
          <input
            type="checkbox"
            id="toggle"
            className="toggle-input"
            checked={isToggled}
            onChange={handleToggleChange}
          />
          <label htmlFor="toggle" className="toggle-label"></label>
        </div>
      </div>
      {isToggled ? (
        <Chart />
      ) : (
        <ProductList
          products={products}
          editButtonHandeler={editButtonHandeler}
          inventoryDetailsHandler={inventoryDetailsHandler}
          deleteButtonHandler={deleteButtonHandler}
        />
      )}
    </Container>
  );
}
