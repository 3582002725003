import { monthUpdate } from "../user/actions";
import { MONTH_UPDATE_FAIL, MONTH_UPDATE_REQUEST } from "../user/actionType";
import axios from "./utils/axios";

const fetchUpdateMonth = (month_dics, id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: MONTH_UPDATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/common/month/update/${id}/`,
        month_dics,
        config
      );
      dispatch(monthUpdate(data));
    } catch (error) {
      dispatch({
        type: MONTH_UPDATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchUpdateMonth;
