import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShipmentReport from "./ShipmentReport";
import fetchShipmentReport from "../../redux/thunk/fetchShipmentReport";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function Report() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const shipmentReport = useSelector((state) => state.shipmentReport);
  const { userInfo } = login || {};
  const { loading, isError, error, shipments } = shipmentReport || {};
  const dispatch = useDispatch();
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const reportView = () => {
    dispatch(fetchShipmentReport(startDate, endDate));
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);
  return (
    <Container>
      {loading && <Loading />}
      {isError && <Error message={error} />}
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Shipment Report</h1>
        </Col>
        <hr />
        <br />
        <br />
        <Col xs={12} md={12} style={{ textAlign: "center" }}>
          <label className="date-level">Start Date:</label>
          <input
            className="date-input"
            type="date"
            value={startDate}
            onChange={(e) => handleStartDateChange(e.target.value)}
            required
          />
          <label className="date-level">End Date:</label>
          <input
            className="date-input"
            type="date"
            value={endDate}
            onChange={(e) => handleEndDateChange(e.target.value)}
            required
          />
        </Col>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Col xs={12} md={12} style={{ textAlign: "center" }}>
          <Button variant="outline-info" size="md" onClick={reportView}>
            run Report
          </Button>
        </Col>
      </Row>
      {Array.isArray(shipments) &&
        (shipments.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              color: "red",
              marginTop: "20px",
            }}
          >
            No Data Found
          </div>
        ) : (
          shipments?.map((shipment, index) => (
            <ShipmentReport shipment={shipment} key={index} />
          ))
        ))}
    </Container>
  );
}
