import React from "react";
import { Container, Row, Table } from "react-bootstrap";

export default function ShipmentReport({ shipment }) {
  return (
    <Container>
      <Row
        className="justify-content-md-center"
        style={{
          marginTop: "20px",
          fontFamily: "sans-serif",
          borderStyle: "groove",
          padding: "10px 6px",
          borderRadius: "12px",
        }}
      >
        <p
          style={{
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          Report for Scheduled Ship Date: {shipment?.shipment_date}
        </p>
        <p style={{ fontSize: "16px" }}>
          <span style={{ fontWeight: "bold" }}>Total Orders Scheduled :</span>{" "}
          {shipment?.total_order}
        </p>
        <p style={{ fontSize: "16px" }}>
          <span style={{ fontWeight: "bold" }}>Total Pullets Scheduled :</span>{" "}
          {shipment?.total_pullet}
        </p>

        <p style={{ fontSize: "16px", fontWeight: "bold", marginTop: "15px" }}>
          Breed Breakdown
        </p>

        <Table striped bordered hover>
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>Breed</th>
              <th>quantity</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {Array.isArray(shipment?.order_list) &&
            shipment?.order_list?.length > 0 ? (
              shipment?.order_list?.map((order, index) => (
                <tr key={order.id || index}>
                  <td>{order?.item}</td>
                  <td>{order?.quantity}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No orders available</td>{" "}
                {/* Corrected colspan to match table columns */}
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}
