import { viewProduct } from "../product/actions";
import { VIEW_PRODUCT_FAIL, VIEW_PRODUCT_REQUEST } from "../product/actionType";
import axios from "./utils/axios";

const fetchViewProduct = (product_id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VIEW_PRODUCT_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`api/product/${product_id}/`, config);
      dispatch(viewProduct(data));
    } catch (error) {
      dispatch({
        type: VIEW_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchViewProduct;
