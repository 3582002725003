import { reportOrder } from "../order/actions";
import { ORDER_REPORT_FAIL, ORDER_REPORT_REQUEST } from "../order/actionType";
import axios from "./utils/axios";

const fetchOrderReport = (searchDate) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_REPORT_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/order/report/${searchDate}/`,
        config
      );
      dispatch(reportOrder(data));
    } catch (error) {
      dispatch({
        type: ORDER_REPORT_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchOrderReport;
