import { pulletList } from "../pullet/actions";
import {
  PULLET_INVENTORY_LIST_FAIL,
  PULLET_INVENTORY_LIST_REQUEST,
} from "../pullet/actionType";
import axios from "./utils/axios";

const fetchPulletList = async (dispatch) => {
  try {
    dispatch({ type: PULLET_INVENTORY_LIST_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.get(`api/pullet/all/`, config);
    dispatch(pulletList(data));
  } catch (error) {
    dispatch({
      type: PULLET_INVENTORY_LIST_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchPulletList;
