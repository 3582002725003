import { updateOrder } from "../order/actions";
import { ORDER_UPDATE_FAIL, ORDER_UPDATE_REQUEST } from "../order/actionType";
import axios from "./utils/axios";

const fetchUpdateOrder = (order) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_UPDATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      console.log(order["shipment_id"]);
      let order_number = order["order_number"];
      const { data } = await axios.put(
        `api/order/update/${order_number}/`,
        order,
        config
      );
      dispatch(updateOrder(data));
    } catch (error) {
      dispatch({
        type: ORDER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchUpdateOrder;
