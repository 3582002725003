import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import fetchShipmentDateList from "../../redux/thunk/fetchShipmentDateList";
import PrintSlipComponent from "./PrintSlipComponent";
import fetchOrderReport from "../../redux/thunk/fetchOrderReport";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchShipmentAvaibility from "../../redux/thunk/fetchShipmentAvaibility";
import fetchShipmentCoverReport from "../../redux/thunk/fetchShipmentCoverReport";
import CoverReport from "./CoverReport";

export default function Slip() {
  const [searchDate, setSearchDate] = useState("");
  const [slipOpen, setSlipOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const shipmentDateList = useSelector((state) => state.shipmentDateList);
  const shipmentCoverReport = useSelector((state) => state.shipmentCoverReport);
  const orderReport = useSelector((state) => state.orderReport);
  const { userInfo } = login || {};
  const { loading, error, isError, shipments } = shipmentDateList || {};
  const {
    loading: shipmentLoading,
    error: shipmentError,
    isError: shipmentIsError,
    shipment,
  } = shipmentCoverReport || {};
  const {
    loading: reportLoading,
    error: reportError,
    isError: reportIsError,
    orders,
  } = orderReport || {};

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const printRef = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleYes = () => {
    setShowModal(false);
    dispatch(fetchShipmentAvaibility(searchDate));
    dispatch(fetchOrderReport(searchDate));
    dispatch(fetchShipmentCoverReport(searchDate));
  };

  const handleNo = () => {
    setShowModal(false);
    dispatch(fetchOrderReport(searchDate));
    dispatch(fetchShipmentCoverReport(searchDate));
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchShipmentDateList);
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (orders) {
      setSlipOpen(orders.length > 0);
    } else {
      setSlipOpen(false);
    }
  }, [orders]);

  const handlePrint = () => {
    return printRef.current;
  };

  return (
    <Container>
      {(loading || reportLoading || shipmentLoading) && <Loading />}

      {(isError || shipmentIsError) && (
        <Error message={isError ? error : shipmentError} />
      )}

      <Row className="justify-content-md-center">
        <Col
          xs={6}
          md={6}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Print Packing Slip</h1>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={6} md={6} sm={12}>
          <Form onSubmit={submitHandler}>
            <Form.Group
              controlId="product"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                Select Ship Date
              </Form.Label>
              <Form.Control
                as="select"
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                required
                style={{ flex: "3" }}
              >
                <option value="">Please Select Date</option>
                {shipments?.map((i, index) => (
                  <option key={index} value={i?.id}>
                    {formatDate(i?.date)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              Print Packing Slip
            </Button>
          </Form>
        </Col>
      </Row>
      {slipOpen && (
        <Row className="justify-content-md-center">
          <Col xs={6} md={6} sm={12}>
            <ReactToPrint
              trigger={() => (
                <button style={{ marginBottom: "20px" }}>Print Slip</button>
              )}
              content={handlePrint}
            />
            <div ref={printRef}>
              <CoverReport shipment={shipment} />
              {Array.isArray(orders) &&
                orders.map((order) => (
                  <PrintSlipComponent key={order?.id} order={order} />
                ))}
            </div>
          </Col>
        </Row>
      )}
      {reportIsError && <Error message={reportError} />}

      {/* Modal for Confirmation */}
      <Modal show={showModal} onHide={handleNo}>
        <Modal.Header closeButton>
          <Modal.Title>AVAILABILITY OPTION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to turn off availability for this ship date?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNo}>
            No
          </Button>
          <Button variant="primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
