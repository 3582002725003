import React from "react";
import logo from "../../assets/logo_slip.png";
import legal from "../../assets/Legals.png";
import { Image } from "react-bootstrap";
import QRCodeComponent from "./QRCode";

const PrintSlipComponent = React.forwardRef((props, ref) => {
  const { order } = props || {};

  return (
    <div
      ref={ref}
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        width: "8in", // Set to 8 inches wide
        height: "10.5in", // Set to 10.5 inches high to account for margins
        margin: "0 auto",
        fontFamily: "serif",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between", // Adjust to space items evenly
          alignItems: "center",
          textAlign: "left", // Align text to the left
        }}
      >
        <Image
          src={logo}
          alt="logo"
          style={{ height: "180px", width: "180px" }} // Adjust size to fit the layout
        />
        <div>
          <div style={{ display: "flex" }}>
            <p
              style={{
                marginRight: "8px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              From:
            </p>
            <p style={{ fontSize: "12px" }}>
              Valley Hatchery <br />
              8289 State Street #143
              <br />
              Kinsman, Ohio 44428
              <br />
              1-800-652-7606
            </p>
          </div>
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
            }}
          >
            <p
              style={{
                marginRight: "8px",
                fontSize: "14px",
              }}
            >
              To:
            </p>
            <p style={{ fontSize: "12px", textAlign: "left" }}>
              {order?.customer_name} <br />
              {order?.shipping_address} <br />
              {order?.city}, {order?.state} {order?.zip_code}
              <br />
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                Call on arrival:
                <label style={{ marginLeft: "4px" }}>
                  {" "}
                  {order?.phone_number}
                  {order?.alt_phone_number !== "" && (
                    <span>
                      , <br />
                      {order?.alt_phone_number}
                    </span>
                  )}
                </label>
              </div>
            </p>
          </div>
        </div>
        <QRCodeComponent value={order?.order_number} size={100} level="H" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ fontSize: "13px", fontWeight: "bold" }}>
          <p>Order : {order?.order_number}</p>
          <p>Shipped: {order?.available_ship_date?.date}</p>
          <p>Status: {order?.order_type}</p>
        </div>
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ background: "#AFAFAF", color: "black" }}>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Qty</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Item</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Note</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "12px" }}>
          {order?.order_list?.map((item, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {item?.quantity}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {item?.items?.product_name}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {item.note}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{
          margin: "30px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Image
          src={legal}
          alt="legal"
          style={{ width: "150px", height: "50px" }}
        />
        <p
          style={{
            width: "380px",
            border: "1px solid gray",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Upon receipt of your started pullets, allow them a day to acclimate in
          their new coop before free-ranging. It may take some time for them to
          get used to their new environment. Provide free-choice layer feed and
          water, and consider adding electrolytes to the water to help with
          recovery from transit. Do not immediately add them to your existing
          flock. Instead, separate the new birds so they can see each other, and
          after a day of acclimating, add them to the roost at night when your
          existing flock is roosting. Monitor the integration closely. Feel free
          to call us with any questions!
        </p>
      </div>
    </div>
  );
});

export default PrintSlipComponent;
