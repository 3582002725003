import {
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  VIEW_PRODUCT_SUCCESS,
} from "./actionType";

export const addProduct = (data) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const viewProduct = (data) => {
  return {
    type: VIEW_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const updateProduct = (data) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const deleteProduct = (data) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const listProduct = (data) => {
  return {
    type: PRODUCT_LIST_SUCCESS,
    payload: data,
  };
};
