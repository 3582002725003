import {
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  VIEW_PRODUCT_FAIL,
  VIEW_PRODUCT_REQUEST,
  VIEW_PRODUCT_SUCCESS,
} from "./actionType";

export const productViewReducer = (state = {}, action) => {
  switch (action.type) {
    case VIEW_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIEW_PRODUCT_SUCCESS:
      return {
        loading: false,
        product: action.payload,
        success: true,
        isError: false,
      };
    case VIEW_PRODUCT_FAIL:
      return {
        loading: false,
        product: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const productAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        loading: false,
        product: action.payload,
        success: true,
        isError: false,
      };
    case ADD_PRODUCT_FAIL:
      return {
        loading: false,
        product: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        product: action.payload,
        success: true,
        isError: false,
      };
    case UPDATE_PRODUCT_FAIL:
      return {
        loading: false,
        product: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        loading: false,
        product: action.payload,
        success: true,
        isError: false,
      };
    case DELETE_PRODUCT_FAIL:
      return {
        loading: false,
        product: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const productListReducer = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
        success: true,
        isError: false,
      };
    case PRODUCT_LIST_FAIL:
      return {
        loading: false,
        products: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
