import { orderList } from "../order/actions";
import { ORDER_LIST_FAIL, ORDER_LIST_REQUEST } from "../order/actionType";
import axios from "./utils/axios";

const fetchOrderList = (orderNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_LIST_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const url = orderNumber
      ? `api/order/all/?order_number=${orderNumber}`
      : `api/order/all/`;
    const { data } = await axios.get(url, config);
    dispatch(orderList(data));
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};

export default fetchOrderList;
