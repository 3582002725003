import {
  ORDER_SHIPMENT_AVAILABLE_DATE_FAIL,
  ORDER_SHIPMENT_AVAILABLE_DATE_REQUEST,
} from "../order/actionType";
import { orderShipmentAvaialbleDateList } from "../order/actions";
import axios from "./utils/axios";

const fetchOrderAvailableDateList = (inputs) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_SHIPMENT_AVAILABLE_DATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();

      if (!userInfo || !userInfo.token) {
        throw new Error("No authentication token found");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `api/order/available_date/`,
        inputs,
        config
      );

      dispatch(orderShipmentAvaialbleDateList(data));
    } catch (error) {
      console.error("Error: ", error);
      dispatch({
        type: ORDER_SHIPMENT_AVAILABLE_DATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchOrderAvailableDateList;
