import { ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST } from "../order/actionType";
import { createOrder } from "../order/actions";
import axios from "./utils/axios";

const fetchCreateOrder = (order) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_CREATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post("api/order/create/", order, config);
      dispatch(createOrder(data));
    } catch (error) {
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchCreateOrder;
