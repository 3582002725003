import React, { useEffect, useMemo, useCallback } from "react";
import { Container, Image, Table, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import SingleChart from "./SingleChart";
import chartButton from "../../assets/bar-chart.png";

export default function Chart() {
  const navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const { userInfo } = login || {};
  const pulletInventoryList = useSelector((state) => state.pulletList);
  const { loading, isError, pullet_list, error } = pulletInventoryList || {};

  const colors = useMemo(
    () => [
      "#D3D3D3", // Light Gray
      "#ADD8E6", // Pale Blue
      "#98FF98", // Mint Green
      "#FFDAB9", // Peach
      "#E6E6FA", // Lavender
      "#FFFACD", // Soft Yellow
      "#FADADD", // Pale Pink
      "#E0FFFF", // Light Cyan
      "#F5F5DC", // Beige
    ],
    []
  );

  const assignColors = useCallback(
    (list) => {
      let lastColor = null;
      return list.map((pullet) => {
        const availableColors = colors.filter((color) => color !== lastColor);
        const randomColor =
          availableColors[Math.floor(Math.random() * availableColors.length)];
        lastColor = randomColor;
        return { ...pullet, color: randomColor };
      });
    },
    [colors]
  );

  const coloredPulletList = useMemo(
    () => assignColors(pullet_list || []),
    [pullet_list, assignColors]
  );

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  return (
    <Container>
      {(loading || isError) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <Loading />}
          {isError && <Error message={error} />}
        </div>
      )}

      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{
            margin: "20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-around",
              width: "400px",
            }}
          >
            <Image
              src={chartButton}
              alt="chart"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <h1>Product Chart</h1>
          </div>
        </Col>
      </Row>
      {!loading && !isError && coloredPulletList.length > 0 && (
        <Table bordered style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Month</th>
              <th>Gross Available</th>
              <th>Reserve</th>
              <th>Net Available</th>
            </tr>
          </thead>
          <tbody style={{ cursor: "pointer" }}>
            {coloredPulletList.map((pullet) => (
              <SingleChart
                key={pullet.id}
                pullet={pullet}
                color={pullet.color}
              />
            ))}
          </tbody>
        </Table>
      )}
      {!loading && !isError && coloredPulletList.length === 0 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          No Data Found
        </div>
      )}
    </Container>
  );
}
