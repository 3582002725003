export const ADD_PRODUCT_REQUEST = "product/ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "product/ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "product/ADD_PRODUCT_FAIL";

export const VIEW_PRODUCT_REQUEST = "product/VIEW_PRODUCT_REQUEST";
export const VIEW_PRODUCT_SUCCESS = "product/VIEW_PRODUCT_SUCCESS";
export const VIEW_PRODUCT_FAIL = "product/VIEW_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "product/UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "product/UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "product/UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "product/DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "product/DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "product/DELETE_PRODUCT_FAIL";

export const PRODUCT_LIST_REQUEST = "product/PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "product/PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "product/PRODUCT_LIST_FAIL";
