import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import backbutton from "../../assets/leftdown.png";
import fetchOrderView from "../../redux/thunk/fetchOrderView";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchOrderStatusChange from "../../redux/thunk/fetchOrderStatusChange";
import fetchOrderCancel from "../../redux/thunk/fetchOrderCancel";

export default function OrderDetails() {
  const [shouldReload, setShouldReload] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const login = useSelector((state) => state.login);
  const orderView = useSelector((state) => state.orderView);
  const orderCancel = useSelector((state) => state.orderCancel);
  const orderStatusChange = useSelector((state) => state.orderStatusChange);
  const { userInfo } = login || {};
  const { error, loading, isError, order } = orderView || {};
  const {
    error: cancelError,
    isError: cancelIsError,
    loading: cancelLoading,
  } = orderCancel || {};
  const {
    loading: changeLoading,
    isError: changeIsError,
    error: changeError,
  } = orderStatusChange || {};

  const statusChangeButton = (e) => {
    e.preventDefault();
    dispatch(fetchOrderStatusChange(orderId));
    setShouldReload(true);
  };

  const backButtonHandle = (e) => {
    e.preventDefault();
    navigate(`/order/`);
  };

  const cancelButton = (e) => {
    e.preventDefault();
    dispatch(fetchOrderCancel(orderId));
    setShouldReload(true);
  };

  const errorConditions = [
    { isError: isError, message: error },
    { isError: cancelIsError, message: cancelError },
    { isError: changeIsError, message: changeError },
  ];

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchOrderView(orderId));
    setShouldReload(false);
  }, [navigate, userInfo, dispatch, orderId, shouldReload]);

  return (
    <Container>
      {(loading || cancelLoading || changeLoading) && <Loading />}
      {errorConditions.map(
        (condition, index) =>
          condition.isError && <Error key={index} message={condition.message} />
      )}
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          sm={12}
          style={{
            textAlign: "center",
            margin: "20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>Order Details</h1>
          <Image
            src={backbutton}
            alt="back"
            style={{
              marginLeft: "30px",
              height: "30px",
              width: "30px",
              cursor: "pointer",
            }}
            onClick={backButtonHandle}
          />
        </Col>
      </Row>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        <Row
          className="justify-content-md-center"
          style={{
            margin: "10px 0px",
          }}
        >
          <Col xs={4} md={4} sm={6}>
            Order Number
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.order_number}
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Customer Name
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.customer_name}
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Shipping Address
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.shipping_address}, {order?.city}, {order?.state}{" "}
            {order?.zip_code}
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Phone Number
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.phone_number}
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Alt Phone Number
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.alt_phone_number === null ? (
              <p style={{ color: "red" }}>Not Data</p>
            ) : (
              order?.alt_phone_number
            )}
          </Col>
        </Row>
        {order?.order_list &&
          order?.order_list.map((item, index) => (
            <Row
              className="justify-content-md-center"
              style={{ margin: "10px 0px" }}
              key={index}
            >
              <Col xs={4} md={4} sm={6}>
                <label style={{ borderBottom: "2px solid green" }}>
                  Product:{" "}
                </label>{" "}
                {item.items.product_name}
                {/* Accessing the product name */}
              </Col>
              <Col xs={4} md={4} sm={6}>
                <label style={{ borderBottom: "2px solid blue" }}>
                  Quantity:{" "}
                </label>
                {item.quantity} {/* Accessing the quantity */}
              </Col>
            </Row>
          ))}

        {/* <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Quantity
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.quantity}
          </Col>
        </Row> */}
        <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Shipment Date
          </Col>
          <Col xs={4} md={4} sm={6}>
            {order?.available_ship_date?.date}
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ margin: "10px 0px" }}
        >
          <Col xs={4} md={4} sm={6}>
            Order Status
          </Col>
          <Col
            xs={4}
            md={4}
            sm={6}
            style={{
              color: order?.order_type === "CANCEL" ? "red" : "#1E9BCF",
            }}
          >
            {order?.order_type}
          </Col>
        </Row>
      </div>
      <Row className="justify-content-md-center" style={{ margin: "20px 0px" }}>
        <Col xs={4} md={4} sm={6}>
          <Button variant="outline-info" onClick={statusChangeButton}>
            Update Order Status
          </Button>
        </Col>
        <Col xs={4} md={4} sm={6}>
          <Button variant="outline-danger" onClick={cancelButton}>
            Cancel Order
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
