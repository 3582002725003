import { orderStatusChange } from "../order/actions";
import {
  ORDER_STATUS_CHANGE_FAIL,
  ORDER_STATUS_CHANGE_REQUEST,
} from "../order/actionType";
import axios from "./utils/axios";

const fetchOrderStatusChange = (order_id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_STATUS_CHANGE_REQUEST });
      const {
        login: { userInfo },
      } = getState();

      if (!userInfo || !userInfo.token) {
        throw new Error("No user token found");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `api/order/status_change/${order_id}/`,
        {},
        config // Pass an empty object as the data payload
      );

      dispatch(orderStatusChange(data));
    } catch (error) {
      console.error("Error:", error.response || error.message);

      dispatch({
        type: ORDER_STATUS_CHANGE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};

export default fetchOrderStatusChange;
