import React from "react";

export default function SingleChart({ pullet, color }) {
  return (
    <>
      <tr style={{ backgroundColor: color }}>
        <td rowSpan={pullet?.pullet_inventory?.length + 1 || 2}>
          {pullet?.product_name}
        </td>
      </tr>
      {Array.isArray(pullet?.pullet_inventory) &&
        pullet?.pullet_inventory?.map((i) => (
          <tr key={i.id} style={{ backgroundColor: color }}>
            <td>{i?.month}</td>
            <td>{i?.gross_available}</td>
            <td>{i?.researve}</td>
            <td>{i?.net_available}</td>
          </tr>
        ))}
    </>
  );
}
