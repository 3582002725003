export const PULLET_INVENTORY_CREATE_REQUEST =
  "pullet/PULLET_INVENTORY_CREATE_REQUEST";
export const PULLET_INVENTORY_CREATE_SUCCESS =
  "pullet/PULLET_INVENTORY_CREATE_SUCCESS";
export const PULLET_INVENTORY_CREATE_FAIL =
  "pullet/PULLET_INVENTORY_CREATE_FAIL";

export const PULLET_INVENTORY_UPDATE_REQUEST =
  "pullet/PULLET_INVENTORY_UPDATE_REQUEST";
export const PULLET_INVENTORY_UPDATE_SUCCESS =
  "pullet/PULLET_INVENTORY_UPDATE_SUCCESS";
export const PULLET_INVENTORY_UPDATE_FAIL =
  "pullet/PULLET_INVENTORY_UPDATE_FAIL";

export const PULLET_INVENTORY_DELETE_REQUEST =
  "pullet/PULLET_INVENTORY_DELETE_REQUEST";
export const PULLET_INVENTORY_DELETE_SUCCESS =
  "pullet/PULLET_INVENTORY_DELETE_SUCCESS";
export const PULLET_INVENTORY_DELETE_FAIL =
  "pullet/PULLET_INVENTORY_DELETE_FAIL";

export const MONTHLY_PULLET_INVENTORY_REQUEST =
  "pullet/MONTHLY_PULLET_INVENTORY_DELETE_REQUEST";
export const MONTHLY_PULLET_INVENTORY_SUCCESS =
  "pullet/MONTHLY_PULLET_INVENTORY_DELETE_SUCCESS";
export const MONTHLY_PULLET_INVENTORY_FAIL =
  "pullet/MONTHLY_PULLET_INVENTORY_DELETE_FAIL";

export const PULLET_INVENTORY_LIST_REQUEST =
  "pullet/PULLET_INVENTORY_LIST_REQUEST";
export const PULLET_INVENTORY_LIST_SUCCESS =
  "pullet/PULLET_INVENTORY_LIST_SUCCESS";
export const PULLET_INVENTORY_LIST_FAIL = "pullet/PULLET_INVENTORY_LIST_FAIL";
