import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import deleteButton from "../../assets/delete.png";
import editButton from "../../assets/edit.png";
import fetchViewProduct from "../../redux/thunk/fetchViewProduct";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchMonth from "../../redux/thunk/fetchMonth";
import fetchMonthlyPulletCreate from "../../redux/thunk/fetchMonthlyPulletCreate";
import Success from "../ui/Success";
import fetchPulletUpdate from "../../redux/thunk/fetchPulletUpdate";
import fetchDeletePullet from "../../redux/thunk/fetchPulletDelete";

export default function DetailsPulletInventory() {
  const { productId } = useParams();
  const [inventory_id, setInventoryId] = useState("");
  const [month, setMonth] = useState("");
  const [gross_available, setGrossAvaialble] = useState("");
  const [researve, setResearve] = useState("");

  const [shouldReload, setShouldReload] = useState(false);
  const [net_available, setNetAvailable] = useState(0);
  const [createSector, setCreateSector] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const login = useSelector((state) => state.login);
  const viewProduct = useSelector((state) => state.viewProduct);
  const monthList = useSelector((state) => state.monthList);
  const updatePulletInventory = useSelector(
    (state) => state.updatePulletInventory
  );
  const createMonthlyPulletInventory = useSelector(
    (state) => state.createMonthlyPulletInventory
  );
  const { loading, isError, error, product } = viewProduct || {};
  const {
    isError: monthIsError,
    error: monthError,
    loading: monthLoading,
    months,
  } = monthList || {};
  const {
    isError: pulletIsError,
    error: pulletError,
    loading: pulletLoading,
    success,
  } = createMonthlyPulletInventory || {};
  const {
    isError: updateIsError,
    error: updateError,
    loading: updateLoading,
    success: updateSuccess,
  } = updatePulletInventory || {};
  const { userInfo } = login || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createPage = (e) => {
    e.preventDefault();
    setCreateSector(true);
  };
  const reset = useCallback(() => {
    setMonth("");
    setGrossAvaialble("");
    setResearve("");
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    let pullet;
    pullet = {
      month: month,
      gross_available: gross_available,
      researve: researve,
    };
    dispatch(fetchMonthlyPulletCreate(productId, pullet));
  };
  const editHandler = (e) => {
    e.preventDefault();
    let pullet;
    pullet = {
      month: month,
      gross_available: gross_available,
      researve: researve,
    };
    dispatch(fetchPulletUpdate(inventory_id, pullet));
  };
  const cancelHandler = (e) => {
    e.preventDefault();
    setCreateSector(false);
    setEditMode(false);
    reset();
  };
  const editButtonHandeler = (id, month, gross_available, researve) => {
    setGrossAvaialble(gross_available);
    setMonth(month);
    setResearve(researve);
    setInventoryId(id);
    setEditMode(true);
  };
  const deleteButtonHandler = (id) => {
    dispatch(fetchDeletePullet(id)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    if (success || updateSuccess) {
      reset();
      setCreateSector(false);
      setEditMode(false);
    }
    dispatch(fetchViewProduct(productId));
    dispatch(fetchMonth);
  }, [
    navigate,
    userInfo,
    dispatch,
    productId,
    success,
    reset,
    updateSuccess,
    shouldReload,
  ]);
  useEffect(() => {
    let value;
    value = Number(gross_available) - Number(researve);
    setNetAvailable(value);
  }, [researve, gross_available]);
  return (
    <Container>
      {(loading || monthLoading || pulletLoading || updateLoading) && (
        <Loading />
      )}

      {(success || updateSuccess) && (
        <Success
          message={
            success
              ? "Inventory Create Successfully."
              : "Inventory Update Successfully."
          }
        />
      )}

      {(updateIsError || pulletIsError || isError || monthIsError) && (
        <Error
          message={
            updateIsError
              ? updateError
              : pulletIsError
              ? pulletError
              : isError
              ? error
              : monthError
          }
        />
      )}
      <Row className="justify-content-md-center">
        <Col
          xs={6}
          md={6}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h2>Product Name: {product?.product_name}</h2>
        </Col>
        <Col
          xs={6}
          md={6}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <Button variant="info" onClick={createPage}>
            Create Inventory Month
          </Button>
        </Col>
      </Row>
      {(createSector || editMode) && (
        <Row className="justify-content-md-center">
          <Col xs={12} md={12} style={{ textAlign: "center" }}>
            <Form
              onSubmit={createSector ? submitHandler : editMode && editHandler}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Form.Group controlId="month" style={{ margin: "10px" }}>
                <Form.Label>Select Month</Form.Label>
                <Form.Control
                  as="select"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  required
                >
                  <option value="">Please Select Month</option>
                  {months?.map((month, index) => (
                    <option key={index} value={month?.id}>
                      {`${month?.month_name} ${month?.year}`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group
                controlId="gross_available"
                style={{ margin: "10px" }}
              >
                <Form.Label>Gross Avaialble</Form.Label>
                <Form.Control
                  type="number"
                  value={gross_available}
                  onChange={(e) => setGrossAvaialble(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="researve" style={{ margin: "10px" }}>
                <Form.Label>Reserved</Form.Label>
                <Form.Control
                  type="number"
                  value={researve}
                  onChange={(e) => setResearve(e.target.value)}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="net_available" style={{ margin: "10px" }}>
                <Form.Label>Net Available</Form.Label>
                <Form.Control
                  type="number"
                  value={net_available}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Button
                variant={
                  createSector
                    ? "outline-success"
                    : editMode && "outline-warning"
                }
                style={{ width: "180px", marginRight: "20px" }}
                type="submit"
              >
                {createSector ? "Create" : editMode && "Update"}
              </Button>
              <Button
                variant="outline-danger"
                onClick={cancelHandler}
                style={{ width: "180px" }}
              >
                Cancel
              </Button>
            </Form>
          </Col>
        </Row>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Month</th>
            <th>Gross Available</th>
            <th>Reserved</th>
            <th>Net Available</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(product?.pullet_inventory) &&
            product?.pullet_inventory?.map((i, index) => (
              <tr key={i?.id}>
                <td>{index + 1}</td>
                <td>{i?.month}</td>
                <td>{i?.gross_available}</td>
                <td>{i?.researve}</td>
                <td>{i?.net_available}</td>
                <td style={{ display: "flex", justifyContent: "space-around" }}>
                  <Image
                    src={editButton}
                    alt="edit"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      editButtonHandeler(
                        i?.id,
                        i?.month_id,
                        i?.gross_available,
                        i?.researve
                      )
                    }
                  />
                  <Image
                    src={deleteButton}
                    alt="delete"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteButtonHandler(i?.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}
