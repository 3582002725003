import { MONTH_CREAETE_FAIL, MONTH_CREAETE_REQUEST } from "../user/actionType";
import { monthAdd } from "../user/actions";
import axios from "./utils/axios";

const fetchCreateMonth = (month_dics) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: MONTH_CREAETE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        "api/common/month/create/",
        month_dics,
        config
      );
      dispatch(monthAdd(data));
    } catch (error) {
      dispatch({
        type: MONTH_CREAETE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchCreateMonth;
