import React from "react";
import QRCode from "react-qr-code";

const QRCodeComponent = ({ value, size, level }) => {
  return (
    // <QRCode value={value} size={size} bgColor={bgColor} fgColor={fgColor} />
    <QRCode value={value} size={size} level={level} includeMargin={true} />
  );
};

export default QRCodeComponent;
