import {
  ALL_MONTH_GET_SUCCESS,
  MONTH_CREAETE_SUCCESS,
  MONTH_DELETE_SUCCESS,
  MONTH_UPDATE_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "./actionType";

export const login = (data) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: data,
  };
};

export const logout = (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });
  localStorage.removeItem("valley_hatghery_pullet_inventory_userInfo");
};

export const month = (data) => {
  return {
    type: ALL_MONTH_GET_SUCCESS,
    payload: data,
  };
};
export const monthDelete = (data) => {
  return {
    type: MONTH_DELETE_SUCCESS,
    payload: data,
  };
};
export const monthAdd = (data) => {
  return {
    type: MONTH_CREAETE_SUCCESS,
    payload: data,
  };
};
export const monthUpdate = (data) => {
  return {
    type: MONTH_UPDATE_SUCCESS,
    payload: data,
  };
};
