import {
  CREATE_SHIPMENT_DATE_FAIL,
  CREATE_SHIPMENT_DATE_REQUEST,
} from "../order/actionType";
import { createShipmentDate } from "../order/actions";
import axios from "./utils/axios";

const fetchCreateShipment = (shipment) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_SHIPMENT_DATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        "api/order/create_shipment/",
        shipment,
        config
      );
      dispatch(createShipmentDate(data));
    } catch (error) {
      dispatch({
        type: CREATE_SHIPMENT_DATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchCreateShipment;
