import { shipmentDateList } from "../order/actions";
import {
  SHIPMENT_DATE_LIST_FAIL,
  SHIPMENT_DATE_LIST_REQUEST,
} from "../order/actionType";
import axios from "./utils/axios";

const fetchShipmentDateList = async (dispatch) => {
  try {
    dispatch({ type: SHIPMENT_DATE_LIST_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.get(`api/order/all_shipment/`, config);
    dispatch(shipmentDateList(data));
  } catch (error) {
    dispatch({
      type: SHIPMENT_DATE_LIST_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchShipmentDateList;
