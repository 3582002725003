import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import deleteButton from "../../assets/delete.png";
import editButton from "../../assets/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchMonth from "../../redux/thunk/fetchMonth";
import fetchCreateMonth from "../../redux/thunk/fetchCreateMonth";
import fetchUpdateMonth from "../../redux/thunk/fetchUpdateMonth";
import fetchDeleteMonth from "../../redux/thunk/fetchDeleteMonth";
import Success from "../ui/Success";

export default function MonthManage() {
  const [monthName, setMonthName] = useState("");
  const [year, setYear] = useState("");
  const [id, setId] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const monthList = useSelector((state) => state.monthList);
  const monthAdd = useSelector((state) => state.monthAdd);
  const monthUpdate = useSelector((state) => state.monthUpdate);
  const monthDelete = useSelector((state) => state.monthDelete);
  const { userInfo } = login || {};
  const { months, loading, isError, error } = monthList || {};
  const {
    loading: addLoading,
    success,
    error: addError,
    isError: addIsError,
  } = monthAdd || {};
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
    isError: updateIsError,
  } = monthUpdate || {};
  const {
    loading: deleteLoading,
    error: deleteError,
    isError: deleteIsError,
  } = monthDelete || {};
  const createMonthHandler = (e) => {
    e.preventDefault();
    let month_dics = {
      month_name: monthName,
      year: year,
    };
    dispatch(fetchCreateMonth(month_dics)).then(() => {
      setMonthName("");
      setYear("");
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };
  const updateMonthHandler = (e) => {
    e.preventDefault();
    let month_dics = {
      month_name: monthName,
      year: year,
    };
    dispatch(fetchUpdateMonth(month_dics, id)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
    setEditMode(false);
    setMonthName("");
    setYear("");
  };
  const editButtonHandeler = (id, month_name, year) => {
    setMonthName(month_name);
    setYear(year);
    setId(id);
    setEditMode(true);
  };
  const deleteButtonHandler = (id) => {
    dispatch(fetchDeleteMonth(id)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };
  const errorConditions = [
    { isError: isError, message: error },
    { isError: addIsError, message: addError },
    { isError: updateIsError, message: updateError },
    { isError: deleteIsError, message: deleteError },
  ];
  const cancelButton = () => {
    setEditMode(false);
    setMonthName("");
    setYear("");
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchMonth);
  }, [navigate, userInfo, dispatch, shouldReload]);
  return (
    <Container>
      {(loading || addLoading || updateLoading || deleteLoading) && <Loading />}
      {errorConditions?.map(
        (condition, index) =>
          condition.isError && <Error key={index} message={condition.message} />
      )}
      {success && <Success message="Month Create Successfully" />}
      {updateSuccess && <Success message="Month Update Successfully" />}
      <Row className="justify-content-md-center">
        <Col>
          <Form
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "20px 0px",
              alignItems: "center",
            }}
            onSubmit={editMode ? updateMonthHandler : createMonthHandler}
          >
            <Form.Group controlId="monthName">
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter Month Name"
                value={monthName}
                onChange={(e) => setMonthName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="year">
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter Year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Button variant={editMode ? "success" : "primary"} type="submit">
              {editMode ? <>Update Month</> : <>Create Month</>}
            </Button>
            {editMode && (
              <Button variant="danger" onClick={cancelButton}>
                Cancel
              </Button>
            )}
          </Form>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{
            margin: "20px 0px",
          }}
        >
          <h1>Month List</h1>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Month Name</th>
            <th>Year</th>
            <th>Action</th>
          </tr>
        </thead>
        {months?.length > 0 ? (
          <tbody>
            {Array.isArray(months) &&
              months?.map((month, index) => (
                <tr key={month.id}>
                  <td>{index + 1}</td>
                  <td>{month.month_name}</td>
                  <td>{month.year}</td>
                  <td
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Image
                      src={editButton}
                      alt="edit"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        editButtonHandeler(
                          month?.id,
                          month?.month_name,
                          month?.year
                        )
                      }
                    />
                    <Image
                      src={deleteButton}
                      alt="delete"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteButtonHandler(month?.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            No Data Found
          </div>
        )}
      </Table>
    </Container>
  );
}
