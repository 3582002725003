import { month } from "../user/actions";
import { ALL_MONTH_GET_FAIL, ALL_MONTH_GET_REQUEST } from "../user/actionType";
import axios from "./utils/axios";

const fetchMonth = async (dispatch, getState) => {
  try {
    dispatch({ type: ALL_MONTH_GET_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/common/month/all/`, config);
    dispatch(month(data));
  } catch (error) {
    dispatch({
      type: ALL_MONTH_GET_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchMonth;
