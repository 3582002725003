import React, { useEffect, useMemo, useCallback } from "react";
import { Container, Image, Table, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import SingleChart from "./SingleChart";
import chartButton from "../../assets/bar-chart.png";
import shipmentImage from "../../assets/trade.png";
import fetchPulletList from "../../redux/thunk/fetchPulletList";
import fetchShipmentDateList from "../../redux/thunk/fetchShipmentDateList";

export default function Avaibility() {
  const dispatch = useDispatch();
  const pulletInventoryList = useSelector((state) => state.pulletList);
  const shipmentDateList = useSelector((state) => state.shipmentDateList);
  const {
    loading: pulletLoading,
    isError: pulletIsError,
    pullet_list,
    error: pulletError,
  } = pulletInventoryList || {};
  const {
    loading: shipmentLoading,
    isError: shipmentIsError,
    error: shipmentError,
    shipments,
  } = shipmentDateList || {};
  const colors = useMemo(
    () => [
      "#D3D3D3", // Light Gray
      "#ADD8E6", // Pale Blue
      "#98FF98", // Mint Green
      "#FFDAB9", // Peach
      "#E6E6FA", // Lavender
      "#FFFACD", // Soft Yellow
      "#FADADD", // Pale Pink
      "#E0FFFF", // Light Cyan
      "#F5F5DC", // Beige
    ],
    []
  );

  const assignColors = useCallback(
    (list) => {
      let lastColor = null;
      return list.map((pullet) => {
        const availableColors = colors.filter((color) => color !== lastColor);
        const randomColor =
          availableColors[Math.floor(Math.random() * availableColors.length)];
        lastColor = randomColor;
        return { ...pullet, color: randomColor };
      });
    },
    [colors]
  );

  const coloredPulletList = useMemo(
    () => assignColors(pullet_list || []),
    [pullet_list, assignColors]
  );

  useEffect(() => {
    dispatch(fetchPulletList);
    dispatch(fetchShipmentDateList);
  }, [dispatch]);
  const isLoading = pulletLoading || shipmentLoading;
  const isError = pulletIsError || shipmentIsError;
  const errorMessage = pulletError || shipmentError;

  return (
    <Container>
      {(isLoading || isError) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoading && <Loading />}
          {isError && <Error message={errorMessage} />}
        </div>
      )}

      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{
            margin: "20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-around",
              width: "400px",
            }}
          >
            <Image
              src={chartButton}
              alt="chart"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <h1>Product Chart</h1>
          </div>
        </Col>
      </Row>
      {!isLoading && !isError && coloredPulletList.length > 0 && (
        <Table bordered style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Month</th>
              <th>Gross Available</th>
              <th>Reserve</th>
              <th>Net Available</th>
            </tr>
          </thead>
          <tbody style={{ cursor: "pointer" }}>
            {coloredPulletList.map((pullet) => (
              <SingleChart
                key={pullet.id}
                pullet={pullet}
                color={pullet.color}
              />
            ))}
          </tbody>
        </Table>
      )}
      {!isLoading && !isError && coloredPulletList.length === 0 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          No Data Found
        </div>
      )}

      <br />
      <br />
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{
            margin: "20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-around",
              width: "250px",
            }}
          >
            <Image
              src={shipmentImage}
              alt="chart"
              style={{
                width: "35px",
                height: "35px",
              }}
            />
            <h1>Shipment</h1>
          </div>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Month</th>
            <th>Ship Date</th>
            <th>Max Pullet</th>
            <th>Max Order</th>
            <th>Total Order</th>
            <th>Total Pullet</th>
            <th>Availability</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {Array.isArray(shipments) &&
            shipments?.map((shipment) => (
              <tr key={shipment?.id}>
                <td>{shipment?.month}</td>
                <td>{shipment?.shipment_date}</td>
                <td>{shipment?.max_pullet}</td>
                <td>{shipment?.max_order}</td>
                <td>{shipment?.total_order}</td>
                <td>{shipment?.total_pullet}</td>
                <td>
                  <p
                    style={{
                      background: shipment?.availability ? "green" : "red",
                      borderTopLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                      color: "white",
                    }}
                  >
                    {shipment?.availability ? "available" : "not available"}
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}
