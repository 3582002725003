import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../App.css";
import fetchProductList from "../../redux/thunk/fetchProductList";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import Success from "../ui/Success";
import fetchMonth from "../../redux/thunk/fetchMonth";
import fetchPulletCreate from "../../redux/thunk/fetchPulletCreate";
// import axios from "../../redux/thunk/utils/axios";

export default function CreatePulletInventory() {
  const [product, setProduct] = useState("");
  const [inputs, setInputs] = useState([
    { month: "", gross_available: "", researve: "", net_available: "" },
  ]);
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const productList = useSelector((state) => state.productList);
  const monthList = useSelector((state) => state.monthList);
  const createPulletInventory = useSelector(
    (state) => state.createPulletInventory
  );
  const dispatch = useDispatch();
  const { userInfo } = login || {};
  const {
    isError: productListIsError,
    error: productListError,
    loading: productListLoading,
    products,
  } = productList || {};
  const {
    isError: MonthIsError,
    error: MonthError,
    loading: MonthLoading,
    months,
  } = monthList || {};
  const {
    isError: PulletIsError,
    error: PulletError,
    loading: PulletLoading,
    success,
  } = createPulletInventory || {};
  const reset = useCallback(() => {
    setProduct("");
    setInputs([
      { month: "", gross_available: "", researve: "", net_available: "" },
    ]);
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    let pullet;
    pullet = {
      product_id: product,
      inventory_list: inputs,
    };
    dispatch(fetchPulletCreate(pullet));
  };
  const handleInputChange = (index, name, value) => {
    setInputs((prevInputs) =>
      prevInputs.map((input, i) => {
        if (i === index) {
          const updatedInput = { ...input, [name]: value };

          // Update net_available only when gross_available or researve changes
          if (name === "gross_available" || name === "researve") {
            const netAvailable =
              Number(updatedInput.gross_available) -
              Number(updatedInput.researve);
            updatedInput.net_available = netAvailable.toString();
          }
          return updatedInput;
        }
        return input;
      })
    );
  };

  // Function to add more input fields
  const handleAddMore = () => {
    setInputs([
      ...inputs,
      { month: "", gross_available: "", researve: "", net_available: "" },
    ]);
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    if (success) {
      reset();
    }
    dispatch(fetchProductList);
    dispatch(fetchMonth);
  }, [navigate, userInfo, dispatch, success, reset]);

  return (
    <Container>
      {(productListLoading || MonthLoading || PulletLoading) && <Loading />}
      {success && <Success message="Pullet Inventory Create Successfully." />}
      {(PulletIsError || productListIsError || MonthIsError) && (
        <Error
          message={
            PulletIsError
              ? PulletError
              : productListIsError
              ? productListError
              : MonthError
          }
        />
      )}

      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Pullet Inventory</h1>
        </Col>
        <hr />
        <br />
        <br />
        <Col xs={12} md={10} style={{ textAlign: "center" }}>
          <Form onSubmit={submitHandler}>
            <Form.Group
              controlId="product"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Form.Label>Select Your Product</Form.Label>
              <Form.Control
                as="select"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                required
              >
                <option value="">Please Select Product</option>
                {products?.map((product, index) => (
                  <option key={index} value={product?.id}>
                    {product?.product_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <br />
            {inputs.map((input, index) => (
              <div key={index} className="d-flex mb-3">
                <Form.Group controlId={`month${index}`} className="mx-3">
                  <Form.Label>Select Your Month</Form.Label>
                  <Form.Control
                    as="select"
                    value={input.month}
                    required
                    onChange={(e) =>
                      handleInputChange(index, "month", e.target.value)
                    }
                  >
                    <option value="">Select Your Month</option>
                    {Array.isArray(months) &&
                      months?.map((month, index) => (
                        <option key={index} value={month?.id}>
                          {`${month?.month_name} ${month?.year}`}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  controlId={`gross_available${index}`}
                  className="mx-3"
                >
                  <Form.Label>Enter Gross Available</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Gross Available"
                    required
                    value={input.gross_available}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "gross_available",
                        e.target.value
                      )
                    }
                  />
                </Form.Group>
                <Form.Group controlId={`researve${index}`} className="mx-3">
                  <Form.Label>Enter Your Reserved</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Reserved"
                    required
                    value={input.researve}
                    onChange={(e) =>
                      handleInputChange(index, "researve", e.target.value)
                    }
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  controlId={`net_available${index}`}
                  className="mx-3"
                >
                  <Form.Label>Net Available</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Net Available"
                    value={input.net_available}
                    disabled
                  />
                </Form.Group>
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="info"
                onClick={handleAddMore}
                className="me-2 my-2"
              >
                Add More
              </Button>
              <Button
                type="submit"
                variant="outline-success"
                style={{ width: "600px" }}
              >
                Create Pullet Inventory
              </Button>
            </div>
          </Form>
        </Col>
        <br />
        <br />
      </Row>
    </Container>
  );
}
