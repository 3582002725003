import { cancelOrder } from "../order/actions";
import { ORDER_CANCEL_FAIL, ORDER_CANCEL_REQUEST } from "../order/actionType";
import axios from "./utils/axios";

const fetchOrderCancel = (order_id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_CANCEL_REQUEST });
      const {
        login: { userInfo },
      } = getState();

      if (!userInfo || !userInfo.token) {
        throw new Error("No user token found");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `api/order/cancel/${order_id}/`,
        {}, // Pass an empty object as the data payload
        config
      );

      dispatch(cancelOrder(data));
    } catch (error) {
      console.error("Error:", error.response || error.message);

      dispatch({
        type: ORDER_CANCEL_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};

export default fetchOrderCancel;
