export const USER_LOGIN_REQUEST = "user/USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "user/USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "user/USER_LOGIN_FAIL";

export const USER_LOGOUT = "user/USER_LOGOUT";

export const ALL_MONTH_GET_REQUEST = "month/ALL_MONTH_GET_REQUEST";
export const ALL_MONTH_GET_SUCCESS = "month/ALL_MONTH_GET_SUCCESS";
export const ALL_MONTH_GET_FAIL = "month/ALL_MONTH_GET_FAIL";

export const MONTH_CREAETE_REQUEST = "month/MONTH_CREAETE_REQUEST";
export const MONTH_CREAETE_SUCCESS = "month/MONTH_CREAETE_SUCCESS";
export const MONTH_CREAETE_FAIL = "month/MONTH_CREAETE_FAIL";

export const MONTH_UPDATE_REQUEST = "month/MONTH_UPDATE_REQUEST";
export const MONTH_UPDATE_SUCCESS = "month/MONTH_UPDATE_SUCCESS";
export const MONTH_UPDATE_FAIL = "month/MONTH_UPDATE_FAIL";

export const MONTH_DELETE_REQUEST = "month/MONTH_DELETE_REQUEST";
export const MONTH_DELETE_SUCCESS = "month/MONTH_DELETE_SUCCESS";
export const MONTH_DELETE_FAIL = "month/MONTH_DELETE_FAIL";
