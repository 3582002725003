import { listProduct } from "../product/actions";
import { PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST } from "../product/actionType";
import axios from "./utils/axios";

const fetchProductList = async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/product/all/`, config);
    dispatch(listProduct(data));
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchProductList;
