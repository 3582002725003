import React from "react";
import { Container, Row, Table } from "react-bootstrap";

const CoverReport = React.forwardRef((props, ref) => {
  const { shipment } = props || {};

  return (
    <Container
      ref={ref}
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        width: "8in", // Set to 8 inches wide
        height: "10.5in", // Set to 10.5 inches high to account for margins
        margin: "0 auto",
        fontFamily: "serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Row
        className="justify-content-md-center"
        style={{
          width: "100%",
          fontFamily: "sans-serif",
          textAlign: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <p
            style={{
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            Report for Scheduled Ship Date: {shipment?.shipment_date}
          </p>
          <p style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>Total Orders Scheduled :</span>{" "}
            {shipment?.total_order}
          </p>
          <p style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>
              Total Pullets Scheduled :
            </span>{" "}
            {shipment?.total_pullet}
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "30px",
              textDecoration: "underline",
            }}
          >
            Breed Breakdown
          </p>

          <Table striped bordered hover>
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Breed</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {Array.isArray(shipment?.order_list) &&
              shipment?.order_list?.length > 0 ? (
                shipment?.order_list?.map((order, index) => (
                  <tr key={order.id || index}>
                    <td>{order?.item}</td>
                    <td>{order?.quantity}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No orders available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Row>
    </Container>
  );
});

export default CoverReport;
